.input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 8px 2px;

  .label {
    font-weight: bold;
    text-transform: uppercase;
    padding: 2px 0;
    color: var(--accent-dark);
  }

  .input {
    width: 100%;
    padding: 8px 12px;
    color: var(--title-foreground);
    border: none;
    border-bottom: 2px solid var(--disabled-foreground);
    outline: none;

    &:focus {
      border-bottom: 2px solid var(--accent-dark);
    }
  }
}
