.file-attach-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	min-height: 48px;
	width: 100%;
	margin: 20px 0;
	border-radius: 4px;
	border: 1px solid var(--accent);
	background-color: var(--foreground-in-accent);
	cursor: pointer;

	.label {
		text-align: center;;
		width: 100%;
		cursor: pointer;
		color: var(--accent);
		font-weight: bold;
		text-transform: uppercase;
	}

	.input {
		display: none;
	}
}