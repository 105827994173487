html, body, #root {
  width: 100%;
  height: 100%;
  background-color: var(--app-background);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --app-background: #162447;
  --container-background: #ffffff;
  --title-foreground: #1b1b2f;
  --text-foreground: #1f4068;
  --accent: #6a097d;
  --accent-light: #c060a1;
  --accent-dark: #00005c;
  --foreground-in-accent: #ffdcb4;
  --disabled-background: #f0f0f0;
  --disabled-foreground: #888888;
}
