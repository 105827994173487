.error-view {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
	background-color: var(--foreground-in-accent);
	padding: 12px;
	width: 100%;
	border-radius: 2px;

	.title {
		height: 40px;
		color: var(--accent-dark);
		font-weight: bold;
	}

	.action {
		position: absolute;
		top: 8px;
		right: 8px;
		background: transparent;
		border: none;
	}

	.message {
		color: var(--accent);
	}
}