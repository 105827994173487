.page-title {
  color: var(--title-foreground);
  padding: 4px 0 20px 0;
}

.page-subtitle {
  color: var(--title-foreground);
  padding: 8px 0 2px 0;
}

.page-message {
  color: var(--text-foreground);
  padding: 6px 0;
}

.link {
  color: var(--accent);
}

.button {
  background-color: transparent;
  border: 1px solid var(--text-foreground);
  border-radius: 4px;
  padding: 8px 16px;
  color: var(--title-foreground);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bolder;
  cursor: pointer;

  &.primary {
    border: 1px solid var(--accent);
    background-color: var(--accent);
    color: var(--foreground-in-accent);
  }

  &.disabled {
    border: 1px solid var(--disabled-background);
    background-color: var(--disabled-background);
    color: var(--disabled-foreground);
    cursor: not-allowed;
  }
}
