.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .content {
  	background-color: var(--container-background);
  	padding: 48px 24px;
  	border-radius: 8px;
  	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  	max-width: 525px;
  	width: 100%;
  	margin: 8px;
  }
}
