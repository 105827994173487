.selector-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    text-transform: uppercase;
    font-weight: bold;
  }

  .selector {
    padding: 8px 12px;

    .option {
      padding: 10px;
    }
  }
}
